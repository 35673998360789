<template>
    <UIBoolean v-model="status" :color="color" textOn="Enabled" textOff="Disabled" />
</template>

<script>
    import { Boolean as UIBoolean } from '@tech_hexeko/design-system'

    export default {
        name: 'WalletStatus',
        components: { UIBoolean },
        props: {
            status: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            color() {
                return !this.status ? 'danger' : null
            },
        },
    }
</script>
